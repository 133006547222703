/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
document.addEventListener('DOMContentLoaded', () => {
  if(process.env.NODE_ENV == 'production') {
    const ids = ['vue-global-settings-2' , 'vue-global-settings']

    ids.forEach(id => {
      let el = document.getElementById(id)

      if (el) {
        (function(c,l,a,r,i,t,y){
          c[a] = c[a] || function(){ (c[a].q = c[a].q || []).push(arguments); };
          t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/"+i;
          y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t,y);
          t.onload = function() {
            if(el.dataset.currentUserEmail){
              clarity("set", "email", el.dataset.currentUserEmail);
            }
          };
      })(window, document, "clarity", "script", "m74bnsebel");
      }
    })
  }
});

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import "../stylesheets/application.scss"

import Rails from '@rails/ujs'
if (!window._rails_loaded) {
  Rails.start()
}

// var Turbolinks = require('turbolinks')
// Turbolinks.start()

import 'alpine-magic-helpers'
import 'alpinejs'

// Stimulus
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()
const context = require.context('../stimulus', true, /\.js$/)
application.load(definitionsFromContext(context))

// Vue
import Vue from 'vue'
import axios from 'axios'
import VModal from 'vue-js-modal'
import Rollbar from 'rollbar';

// import TurbolinksAdapter from 'vue-turbolinks'
import GlobalSettings from '../vue/GlobalSettings.vue'

// Vue.use(TurbolinksAdapter)
Vue.use(VModal)

document.addEventListener('DOMContentLoaded', () => {
  window.scrollTo(0, 0);
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }
  Vue.prototype.$axios = axios
  Vue.prototype.$axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  loadRollbar();
  loadCountrySelect()
  readAllNotifications()
})

function loadCountrySelect () {
  const ids = ['vue-global-settings-2' , 'vue-global-settings']

  ids.forEach(id => {
    let el = document.getElementById(id)

    if (el) {
      let selectedCountry
      let path = window.location.pathname
      if(path.includes("/discover") && el.dataset.currentCity) {
        let changeurl = path.split('/')[2] && el.dataset.urlCountryName && el.dataset.urlCity == 'true' ? false : true
        if(changeurl) {
          let url = window.location.origin + path
          let parts = path.split('/').filter(part => part);
          let newUrl=''
          if (parts[0] === "discover" && !parts[1]) {
            newUrl = `/discover/${el.dataset.currentCity.toLowerCase()}`
          }
          let constructedUrl = new URL(newUrl, url);
          history.replaceState({turbolinks: true}, '', constructedUrl)
        }
      }
      if (el.dataset.urlCountryName) {
        selectedCountry = {
          name: el.dataset.urlCountryName,
          id: el.dataset.urlCountryId,
          slug: el.dataset.urlCountrySlug
        }
        localStorage.setItem('location_country', el.dataset.urlCountrySlug)
        localStorage.setItem('country_id', el.dataset.urlCountryId)
        localStorage.setItem('short_form', el.dataset.urlCountryShortForm)
      }
      else {
        if (el.dataset.param) {
          window.history.pushState({}, '', el.dataset.countryShortForm)
        }
        selectedCountry = {
          id: el.dataset.countryId,
          name: el.dataset.countryName,
          slug: el.dataset.countrySlug
        }
      }
      const props = {
        selectedCountry: selectedCountry,
        selectedLanguage: {
          id: el.dataset.languageId,
          name: el.dataset.languageName
        },
        selectedCurrency: {
          id: el.dataset.currencyId,
          code: el.dataset.currencyCode,
          name: el.dataset.currencyName
        }
      }

      new Vue({
        el: el,
        render: h => h(GlobalSettings, { props })
      })
    }
  })
}

function readAllNotifications() {
  const checkBoxs = [document.getElementById('mark-all-as-read'), document.getElementById('read-all-notifications')];
  checkBoxs.forEach(checkBox => {
    if (checkBox !== null) {
      checkBox.addEventListener('click', function() {
        fetch('/notifications/read', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.head.querySelector('meta[name=csrf-token]').content
          },
          body: JSON.stringify({read_all: true})
        })
        .then(response => response.json())
        .then(data => {
          if(data.is_reload) {
            location.reload();
          }
        })
      })
    }
  })
}

function loadRollbar(){
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: "a9f1f409f49a458488d40d466ffda2e1",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.NODE_ENV,
      client: {
        javascript: {
          code_version: '1.0.0',
        }
      }
    },
  });

  Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
  };
}